<template>
  <div class="pl-3 lg:pl-0">
    <div ref="wrapper" class="wrapper">
      <ul ref="skeleton-carousel" class="skeleton-carousel snap-x snap-mandatory">
        <li v-for="i in 5" :key="i" ref="card" class="size-full max-h-[490px] bg-inherit px-2">
          <div
            class="flex size-full flex-col justify-between rounded-3xl bg-white shadow-around shadow-black/25 dark:bg-[#0F2775] dark:shadow-[#00bbba]"
          >
            <div>
              <div
                class="aspect-square size-full max-h-64 animate-pulse rounded-t-3xl bg-gray-300 dark:dark:bg-[#2D059E]"
              ></div>
              <div class="-mt-8 flex flex-col items-center gap-0.5 px-5">
                <div>
                  <div
                    class="block aspect-square size-16 rounded-full bg-gray-300 shadow-around shadow-black/25 dark:dark:bg-[#2D059E]"
                  ></div>
                </div>
                <p
                  class="mt-1 line-clamp-2 w-full animate-pulse rounded-2xl bg-gray-300 text-base font-semibold text-transparent dark:dark:bg-[#2D059E] 2xl:text-base"
                >
                  Lorem, ipsum.
                </p>
                <div
                  class="w-full animate-pulse rounded-2xl bg-gray-300 text-base text-transparent dark:dark:bg-[#2D059E] 2xl:text-sm"
                >
                  by Lorem ipsum dolor sit.
                </div>
                <p
                  class="line-clamp-2 animate-pulse rounded-2xl bg-gray-300 text-base text-transparent dark:dark:bg-[#2D059E] xl:text-lg 2xl:text-2xl"
                >
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam dicta error nobis enim eos,
                  asperiores in distinctio expedita delectus voluptatibus quas ullam natus voluptate illum sit iure
                  eaque cupiditate. Fugiat.
                </p>
              </div>
            </div>
            <div class="mt-2 w-full px-5 pb-5">
              <div class="h-8 w-full animate-pulse rounded-2xl bg-gray-300 dark:dark:bg-[#2D059E] xl:h-7"></div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
