<script setup>
import { useScroll } from "@vueuse/core";

import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/vue/24/outline";

const emit = defineEmits(["giveMeMore"]);

const props = defineProps({
  breakpoints: {
    type: Object,
    default() {
      return { xs: 1, sm: 1, md: 1, lg: 1, xl: 1, "2xl": 1 };
    },
  },
  autoscroll: {
    type: Boolean,
    default: false,
  },
  infinite: {
    type: Boolean,
    default: false,
  },
  total: {
    type: Number,
    default: 0,
  },
  count: {
    type: Number,
    default: 0,
  },
});

// ==================== CONSTANTS ====================

let autoScrollInterval;
let remainingRequests = props.count ? Math.ceil(props.total / props.count) - 1 : 0;

const wrapper = ref(null);
const carousel = ref(null);
const firstCardWidth = ref(300);

const isDragging = ref(false);
const showLeftArrow = ref(false);
const arrivedRight = ref(false);
const startX = ref();
const startScrollLeft = ref();
const { arrivedState } = useScroll(carousel);

// ==================== FUNCTIONS ====================

function dragStart(e) {
  isDragging.value = true;
  // Records the initial cursor and scroll position of the carousel
  startX.value = e.pageX;
  startScrollLeft.value = carousel?.value.scrollLeft;
}

function dragging(e) {
  if (!isDragging.value) return; // if isDragging is false return from here
  // Updates the scroll position of the carousel based on the cursor movement
  carousel.value.scrollLeft = startScrollLeft.value - (e.pageX - startX.value);
}

function dragStop() {
  isDragging.value = false;
}

// ==================== AUTO-SCROLL FUNCTIONALITY ====================
function startAutoScroll() {
  autoScrollInterval = setInterval(() => {
    moveCarousel(); // Change the direction based on your preference
  }, 2000);
}

function stopAutoScroll() {
  clearInterval(autoScrollInterval);
}

function moveCarousel() {
  carousel.value.scrollLeft += firstCardWidth.value;

  if (props.infinite) {
    // For infinite scrolling, check if we reached the end or the beginning and adjust the position accordingly.
    if (carousel.value.scrollLeft >= carousel.value.scrollWidth - carousel.value.clientWidth) {
      // Move to the first card when reaching the end
      carousel.value.scrollLeft = 0;
    }
  }
}

onBeforeUnmount(() => {
  stopAutoScroll();
});
// ==================== AUTO-SCROLL FUNCTIONALITY ====================

function scrollWithButton(direction) {
  carousel.value.scrollLeft += direction === "left" ? -firstCardWidth.value : firstCardWidth.value;
}

// ==================== VARIABLES ====================
// Calculate the number of visible slides based on the wrapper's width and the width of the first slide
const visibleSlides = computed(() => {
  const wrapperWidth = wrapper.value?.offsetWidth || 0;
  const firstSlideWidth = firstCardWidth.value;
  return wrapperWidth && firstSlideWidth ? Math.floor(wrapperWidth / firstSlideWidth) : 0;
});

const showRightArrow = computed(() => {
  return props.count ? !arrivedRight.value && visibleSlides.value < props.count : false;
});

// ==================== MOUNTED ====================

onMounted(async () => {
  firstCardWidth.value = carousel.value.firstElementChild.offsetWidth;
  document.addEventListener("mouseup", dragStop);
  if (props.autoscroll) {
    startAutoScroll();
  }
});

// ==================== WATCHERS ====================

watch(
  () => arrivedState.right,
  (value) => {
    if (value && remainingRequests > 0) {
      remainingRequests -= 1;

      emit("giveMeMore");
    }
    arrivedRight.value = value;
  }
);

watch(
  () => arrivedState.left,
  (value) => {
    showLeftArrow.value = !value;
  }
);
</script>

<template>
  <div class="h-full pl-3 lg:pl-0">
    <div ref="wrapper" class="wrapper">
      <AtomButton
        v-if="showLeftArrow"
        type="button"
        aria-label="arrow left"
        class="carousel-btn"
        @click="scrollWithButton('left')"
      >
        <ChevronLeftIcon class="dark:text-white" />
      </AtomButton>
      <ul
        ref="carousel"
        class="carousel snap-x snap-mandatory"
        @mousedown="dragStart"
        @mousemove="dragging"
        @mouseleave="isDragging = false"
      >
        <slot name="content" />
      </ul>
      <AtomButton
        v-if="showRightArrow"
        type="button"
        aria-label="arrow right"
        class="carousel-btn"
        @click.stop="scrollWithButton('right')"
      >
        <ChevronRightIcon class="dark:text-white" />
      </AtomButton>
    </div>
  </div>
</template>
